import {useFormikContext} from 'formik';
import {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {FormikControlProps} from './formik-control-props';

type FormikSubmitButtonProps = {
  id?: string,
  children?: any,
  label?: string,
  activeLabel?: string,
  delay?: number,
  onClick?: (evt) => void,
  disabled?: boolean,
} & FormikControlProps;

export const FormikSubmitButton = (props:FormikSubmitButtonProps) => {
  const formik = useFormikContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {label, activeLabel, delay=0, children, ...other} = props;

  const buttonProps: any = {
    type: 'submit',
    ...other,
  };

  useEffect(() => {
    if (formik.isValidating){
      return;
    }
    if (formik.isSubmitting === isSubmitting) {
      return;
    }
    if (!formik.isSubmitting){
      setTimeout(() => setIsSubmitting(formik.isSubmitting), delay *1000);
    } else {
      if (Object.values(formik.errors).length === 0){
        setIsSubmitting(formik.isSubmitting);
      }
    }
  }, [formik.isSubmitting, formik.isValidating, delay, formik.errors, isSubmitting]);

  const disabled = isSubmitting || props.disabled;

  return (
    <Button onClick={props.onClick} {...buttonProps} className={`form-control btn btn-primary formik-submit-button ${props.className}`} disabled={disabled}>
      {isSubmitting && <i className='fa fa-spinner fa-spin'/>}
      {isSubmitting && activeLabel }
      {!isSubmitting && (children || label) }
    </Button>
  );
};
