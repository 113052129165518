import React from 'react';
import './app.scss';
import {BrowserRouter as Router} from 'react-router-dom';
import appRoutes from './app.routes';
import {
  AlertDialog,
  ConfirmationDialog, LoadingIndicator,
  RouteWithSubRoutes,
  standardNotificationActions,
  StandardNotificationPanel,
} from '@framework/react';
import {loginActions, selectAccessToken, selectUserProfile, UserService} from '@apps/core';
import {Provider, useDispatch, useSelector} from 'react-redux';
import appStore from './reducers/app.store';
import {CurrentUserContext} from '@apps/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {globalConfigRegistry} from '@framework/core';


function useAppBootstrap() {
  // const appConfig = globalConfigRegistry.appConfig;

  const user = useSelector(selectUserProfile);
  const token = useSelector(selectAccessToken);
  const dispatch = useDispatch();

  globalConfigRegistry.setAccessToken('default', token || '');

  // configure rtk query error handler
  RtkQueryUtils.resultHandlers.setDefaultErrorHandler((result) => {
    if (result.error){
      const {error} = result;
      dispatch(standardNotificationActions.notifyError(RtkQueryUtils.getError(error)));
    }
  });

  const checkSessionQuery = UserService.useCheckSessionQuery();
  const active = checkSessionQuery.data?.active || false;
  let initialised = false;

  if (RtkQueryUtils.isQuerySuccess(checkSessionQuery)){
    if (active === false){
      dispatch(loginActions.logoutSuccess());
    }
    initialised = true;
  }

  return {
    userProfile: user,
    initialised: initialised,
  };
}


function AppWithBootstrap(){
  const bootstrap = useAppBootstrap();
  if (!bootstrap.initialised){
    return <LoadingIndicator/>;
  }
  return (
    <CurrentUserContext.Provider value={bootstrap.userProfile}>
      <Router>
        <RouteWithSubRoutes routes={appRoutes}/>
      </Router>
      <StandardNotificationPanel />
      <ConfirmationDialog/>
      <AlertDialog/>
    </CurrentUserContext.Provider>
  );
}
export function App(){
  return (
    <Provider store={appStore}>
      <AppWithBootstrap/>
    </Provider>
  );
}

export default App;
