import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi, EndpointBuilder} from '@reduxjs/toolkit/query/react';
import {User} from '@apps/core/src/models/entities/user';
import {PaginationParams} from '@apps/core/src/models/app/pagination-params';

const QueryTypes = {
  Teachers: 'Teachers',
  Clients: 'Clients',
  User: 'User',
  Assignments: 'Assignments',
};

function getUrl(relativePath:string){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/admin/users/${relativePath}.json`;
}

function getStudentsQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{users: User[]}, {is_active?: number} & PaginationParams>({
    query: ({page=1, limit=25}) => {
      return {
        url: getUrl('clients'),
        params: {page, limit},
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.users, QueryTypes.Clients),
  });
}

function getTeachersQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{users: User[]}, PaginationParams>({
    query: ({page=1, limit=25}) => {
      return {
        url: getUrl('teachers'),
        params: {page, limit},
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.users, QueryTypes.Clients),
  });
}

// function getSessionAssignmentsQuery(build){
//   return build.query({
//     query: (id) => {
//       return {
//         url: getUrl(`${id}/sessions`),
//       };
//     },
//     providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result.sessions, QueryTypes.Assignments),
//   });
// }

function getQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{user: User}, number>({
    query: (id) => {
      return {
        url: getUrl(`${id}`),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result?.user, QueryTypes.User),
  });
}

function refreshUsersMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{result: {clients: number, teachers: number}}, void>({
    query: () => {
      return {
        url: getUrl('refresh'),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => {
      const array1 = RtkQueryUtils.tagProviders.listTags(result, QueryTypes.Clients);
      const array2 = RtkQueryUtils.tagProviders.listTags(result, QueryTypes.Teachers);
      return [...array1, ...array2];
    },
  });
}


export const UsersServiceOptions = {
  reducerPath: 'UsersService',
  keepUnusedDataFor: 60*15,
  baseQuery: fetchAppBaseQuery('UsersService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build:EndpointBuilder<any, string, any>) => ({
    getTeachers: getTeachersQuery(build),
    getStudents: getStudentsQuery(build),
    refreshUsers: refreshUsersMutation(build),
    // getSessionAssignments: getSessionAssignmentsQuery(build),
    get: getQuery(build),
  }),
};

export const UsersService = createApi(UsersServiceOptions);
