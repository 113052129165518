import {Button} from 'react-bootstrap';
import {UsersService} from '@app-admin/core';
import {useDispatch} from 'react-redux';
import {standardNotificationActions} from '@framework/react';
import {useState} from 'react';

type SyncUsersButtonProps = {
  children: any,
  className?: string,
  size?: string,
}

export function SyncUsersButton(props: SyncUsersButtonProps){
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [syncTrigger] = UsersService.useRefreshUsersMutation();
  const handleClick = function(){
    setDisabled(true);
    syncTrigger().then(() => dispatch(standardNotificationActions.notifySuccess('teachers and students are synced')))
      .then(() => setDisabled(false));
  };

  return <Button className={props.className} disabled={disabled} onClick={handleClick}>{props.children}</Button>;
}
