import {createSlice} from '@reduxjs/toolkit';
import {isBlank} from '@framework/core';
import moment from 'moment-timezone';

// @ts-ignore
const bookingUser = localStorage.getItem('booking-user') || '';
// @ts-ignore
const bookingToken = localStorage.getItem('booking-token') || '';

const user = !isBlank(bookingUser)? JSON.parse(bookingUser) : null;
const token = bookingToken;


type UserSessionState = {
  loggedIn: boolean,
  user?: any,
  token?: string | null
};

let initialState:UserSessionState = {loggedIn: false, user: null, token: null};

if (user){
  initialState = {loggedIn: true, user: user, token: token};
}

const userSessionSlice = createSlice({
  name: 'userSession',
  initialState: initialState,
  reducers: {
    loginSuccess(state, action) {
      const payload = action.payload;
      state.user = payload.user;
      state.token = payload.token;

      localStorage.setItem('booking-user', JSON.stringify(payload.user));
      localStorage.setItem('booking-token', payload.token);
    },
    logoutSuccess(state){
      state.user = null;
      state.token = null;
      localStorage.removeItem('booking-user');
      localStorage.removeItem('booking-token');
    },
  },
});

export const loginActions = userSessionSlice.actions;

export const selectUserProfile = (state) => {
  let user = state.session.user;
  if (user){
    user = {...state.session.user};
    user.timezone = user.timezone || moment.tz.guess();
  }
  return user;
};

export const selectAccessToken = (state) => {
  return state.session.token;
};

export const UserSessionReducer = userSessionSlice.reducer;
