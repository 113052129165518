import {ClassSessionsService, ProductsService, UsersService} from '@app-admin/core';
import {RtkQueryUtils} from '@framework/rtk-query';
import {Form, Formik} from 'formik';
import {LoadingIndicator} from '@apps/core/src/components/loading-indicator';
import {FormGroup, FormLabel} from 'react-bootstrap';
import {
  FormikDatePicker,
  FormikSelect,
  FormikSubmitButton,
  FormikTextArea,
  FormikTextField,
} from '@framework/react-formik';
import {useDispatch} from 'react-redux';
import {standardNotificationActions} from '@framework/react';
import moment from 'moment-timezone';
import * as yup from 'yup';
import './class-session-form.scss';
import {Product, User} from '@apps/core';
import {LocalTime} from '@apps/core/src/components/local-time';
import {OnlineMeetingsService} from '@app-admin/core/src/services/online-meetings.service';


const ClassSessionFormSchema = yup.object().shape({
  product_id: yup.number().required(),
  po_product_id: yup.number().required(),
  po_price: yup.number().required(),
  host_user_id: yup.number().required(),
  date: yup.string().required(),
  duration: yup.string().required(),
  start: yup.string().required().matches(/^\d{2}:\d{2}$/, 'Time format must be hh:mm, example: 07:01'),
});

function ClassSessionCloneParamBody(props){
  return <div className='clone-params form-inline'>
    <div className='clone-description'>
      Please specify how you would like to copy the data:
    </div>
    <div className='form-row row'>
      {/*<div className='col-md-7'>*/}
      {/*  <FormGroup className='form-group'>*/}
      {/*    <label>Options</label>*/}
      {/*    <FormikCheckbox name='additional.copy_students' label='Copy Students Assginments'/>*/}
      {/*  </FormGroup>*/}
      {/*</div>*/}
      <div className='col-md-5'>
        <FormGroup className='form-group'>
          <label>Total Copies</label>
          <FormikTextField name='additional.total' size='sm' placeholder='Total (default: 1)'/>
        </FormGroup>
      </div>
    </div>
  </div>;
}

type ClassSessionFormBodyProps = {
  formik: any,
  topics: Product[],
  invoiceTiers: Product[],
  teachers: User[]
};

function ClassSessionFormBody(props: ClassSessionFormBodyProps){
  const {formik, invoiceTiers=[], topics=[], teachers} = props;

  const handlePoProductChange = (evt, val) => {
    const opt = invoiceTiers.find((e) => e.id.toString() === val);
    formik.setFieldValue('po_price', opt?.price || 9999);
  };

  const onlineMeetingQuery = OnlineMeetingsService.useGetByHostUserQuery(
    formik.values.host_user_id,
    {skip: !formik.values.host_user_id},
  );

  const data = onlineMeetingQuery.data;
  const meetings = (data? data.meetings:[]);

  const handleOnlineMeetingChange = function(evt, meeting_id: string) {
    const meeting = meetings.find((item) => item.id.toString() === meeting_id);
    if (meeting){
      formik.setFieldValue('meeting_link', meeting.link);
    }
  };

  return <>
    <FormGroup className='form-group'>
      <FormLabel>Topic</FormLabel>
      <FormikSelect options={topics} name='product_id' className='form-control'
        getOptionLabel={(opt) => `${opt.product_key} | ${opt.name}`}
        getOptionValue={(opt) => opt.id }/>
    </FormGroup>
    <FormGroup className='form-group'>
      <FormLabel>Invoice/PO Category</FormLabel>
      <div className='form-row row'>
        <div className='col-md-9'>
          <FormikSelect options={invoiceTiers} name='po_product_id' className='form-control'
            onChange={handlePoProductChange}
            getOptionLabel={(opt) => `${opt.product_key} | ${opt.price}`}
            getOptionValue={(opt) => opt.id }/>
        </div>
        <div className='col-md-3'>
          <FormikTextField className='form-control' name='po_price' placeholder='price'/>
        </div>
      </div>
    </FormGroup>
    <FormGroup className='form-group'>
      <FormLabel>Teacher</FormLabel>
      <FormikSelect options={teachers} name='host_user_id' className='form-control'
        getOptionLabel={(opt) => opt.name}
        getOptionValue={(opt) => opt.id }/>
    </FormGroup>
    <FormGroup className='form-group'>
      <div className='row form-row'>
        <div className='col-md-6'>
          <FormLabel>Date</FormLabel>
          <FormikDatePicker className='form-control' name='date'/>
        </div>
        <div className='col-md-3'>
          <FormLabel>Time</FormLabel>
          <FormikTextField className='form-control' name='start' placeholder='hh:mm (12:33)'/>
        </div>
        <div className='col-md-3'>
          <FormLabel>Duration</FormLabel>
          <FormikTextField className='form-control' name='duration' placeholder='60' maxLength={3}/>
        </div>
      </div>
    </FormGroup>
    <FormGroup>
      <ul className='customer-local-time clearfix'>
        <li>
          <span className='country'>UK:</span>
          <LocalTime date={formik.values.date} time={formik.values.start} timezone='Europe/London'/>
        </li>
        <li>
          <span className='country'>China:</span>
          <LocalTime date={formik.values.date} time={formik.values.start} timezone='Asia/Shanghai'/>
        </li>
        <li>
          <span className='country'>Singapore:</span>
          <LocalTime date={formik.values.date} time={formik.values.start} timezone='Asia/Singapore'/>
        </li>
      </ul>
    </FormGroup>
    <FormGroup className='form-group'>
      <FormLabel>Meeting Link</FormLabel>
      <FormikSelect name='online_meeting_id' options={meetings}
        placeholder='-- New Meeting Link ---'
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        onChange={handleOnlineMeetingChange} />
      <FormikTextField className='form-control meeting-link-url' name='meeting_link' placeholder='Meeting Url'
        disabled={formik.values.online_meeting_id} />
    </FormGroup>
    <FormGroup className='form-group'>
      <FormLabel>Vendor Invoice Number</FormLabel>
      <FormikTextField className='form-control' name='vendor_invoice_number' placeholder='Vendor Invoice Number'/>
    </FormGroup>
    <FormGroup className='form-group'>
      <FormLabel>Comments</FormLabel>
      <FormikTextArea className='form-control' name='comments' placeholder='Comments'/>
    </FormGroup>
    <FormGroup className='form-group' style={{marginTop: '1rem'}}>
      <FormikSubmitButton name='submit' className='form-control'>Submit</FormikSubmitButton>
    </FormGroup>
  </>;
}


type ClassSessionFormModel = {
  id?: number,
  product_id?: number,
  po_product_id?: number,
  po_price?: number,
  host_user_id?: number,
  date?: string,
  duration?: number,
  online_meeting_id?: number,
  meeting_link?: string,
  start?: string,
  vendor_invoice_number?: string,
  comments?: string,
  additional?: {
    copy_students: boolean,
    total: number,
  },
}

export function ClassSessionForm(props){
  const {session = {}, onSave, isClone=false} = props;

  const dispatch = useDispatch();

  // const query = ClassSessionsService.useGetClassSessionQuery(sessionId, {skip: !isDefined(sessionId)});
  const productsQuery = ProductsService.useGetProductsQuery({is_active: 1});
  const teachersQuery = UsersService.useGetTeachersQuery({limit: 500});
  const [addTrigger] = ClassSessionsService.useCreateClassSessionMutation();
  const [cloneTrigger] = ClassSessionsService.useCloneClassSessionMutation();
  const [updateTrigger] = ClassSessionsService.useUpdateClassSessionMutation();

  const isLoading = RtkQueryUtils.isLoading(productsQuery, teachersQuery);

  if (isLoading){
    return <LoadingIndicator/>;
  }

  // const session = query.data?.session || {duration: 60};

  const initialValue: ClassSessionFormModel = {
    id: session.id,
    product_id: session.product_id,
    po_product_id: session.po_product_id,
    po_price: session.po_price,
    host_user_id: session.host_user_id,
    date: moment(session.date).format('YYYY-MM-DD'),
    duration: session.duration || 60,
    online_meeting_id: session.online_meeting_id,
    meeting_link: session.meeting_link,
    start: moment(session.start).format('HH:mm'),
    vendor_invoice_number: session.vendor_invoice_number,
    comments: session.comments,

  };

  const additionalInitialValue = {
    copy_students: true,
    total: 1,
  };

  const products = productsQuery.data?.products || [];
  const teachers = teachersQuery.data?.users || [];

  const handleSubmit = (values, formik) => {
    let trigger = values.id > 0? updateTrigger:addTrigger;
    if (isClone){
      trigger = cloneTrigger;
    }

    const dt = moment(`${values.date}T${values.start}:00`);
    const start = dt.add(new Date().getTimezoneOffset(), 'minutes')
      .format('YYYY-MM-DDTHH:mm:00');

    const data = {...values};
    data.start = start;
    trigger(data)
      .then((session) => {
        dispatch(standardNotificationActions.notifySuccess('Session Saved'));
        if (onSave){
          onSave(session);
        }
      })
      .finally(() => formik.setSubmitting(false));
  };

  const topics = products.filter((item) => item.product_key.toLowerCase().indexOf('vendor') === -1);
  const invoiceTiers = products.filter((item) => item.product_key.toLowerCase().indexOf('vendor') !== -1);

  if (isClone){
    initialValue.additional = additionalInitialValue;
  }

  return <div className='class-session-form'>
    <Formik initialValues={initialValue} onSubmit={handleSubmit} validationSchema={ClassSessionFormSchema}>
      {(formik) => {
        return <Form className='form'>
          {isClone && <ClassSessionCloneParamBody></ClassSessionCloneParamBody>}
          <ClassSessionFormBody invoiceTiers={invoiceTiers} topics={topics} formik={formik} teachers={teachers}/>
        </Form>;
      }}
    </Formik>
  </div>;
}
