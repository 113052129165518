import moment from 'moment/moment';
import './sessions-list-box.scss';
import {DeleteIconButton} from '@apps/core/src/components/delete-icon-button';
import {EditIconButton} from '@apps/core/src/components/edit-icon-button';
import {SessionUtils} from '../utils/session-utils';
import {useDispatch} from 'react-redux';
import {ClassSessionFormDialog} from './class-session-form-dialog';
import {ConfirmationDialog, dismissDialog, showDialog, useDialogActionListener} from '@framework/react';
import {ClassSessionsService} from '@app-admin/core';
import {DuplicateIconButton} from '@apps/core/src/components/duplicate-icon-button';
import {useCallback, useState} from 'react';
import {ClassSession} from '@apps/core';

type SessionsListBoxProps = {
  sessions: ClassSession[],
  onSelect: (session: ClassSession) => void,
}

export function SessionsListBox(props:SessionsListBoxProps){
  const {sessions} = props;
  const dispatch = useDispatch();
  const [selectedSession, setSelectedSession] = useState({id: 0});

  const [deleteTrigger] = ClassSessionsService.useDeleteClassSessionMutation();
  const handleEdit = function(session){
    dispatch(showDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId, session));
  };

  const handleDelete = function(session){
    dispatch(showDialog(ConfirmationDialog, 'confirm-delete-session', session));
    // dispatch(showDialog({id: 'confirm-delete-session', data: session}));
  };

  const handleDuplicate = function(session){
    const duplicatedSession = {...session};
    duplicatedSession.id = null;
    duplicatedSession.status = 1;
    duplicatedSession.comments = null;
    duplicatedSession.vendor_invoice_number = null;
    duplicatedSession.locked = 0;
    duplicatedSession.date = moment(duplicatedSession.date).add(7, 'days').format('YYYY-MM-DD');
    dispatch(showDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId, duplicatedSession));
  };

  const handleDeleteConfirmed = function(session){
    return deleteTrigger(session.id);
  };

  const handleRowSelect = function(item){
    props.onSelect(item);
    setSelectedSession(item);
  };

  const handleSessionSaved = useCallback((session) => {
    dispatch(dismissDialog(ClassSessionFormDialog, ClassSessionFormDialog.dialogId));
  }, [dispatch]);

  useDialogActionListener(ClassSessionFormDialog, ClassSessionFormDialog.dialogId,
    ClassSessionFormDialog.Actions.Saved, handleSessionSaved);

  return <div className='class-session-list-box'>
    <div>
      <ConfirmationDialog id='confirm-delete-session' onConfirm={handleDeleteConfirmed}>
        Do you want to delete the session?
      </ConfirmationDialog>
      <div className='session-list'>
        {sessions.map((item) => {
          const canEdit = SessionUtils.canEdit(item);
          const canDelete = SessionUtils.canDelete(item);

          return <div key={item.id} onClick={() => handleRowSelect(item) } className={`session-list-item session-${SessionUtils.getStatusDisplayValue(item.status).toLowerCase()} ${selectedSession.id === item.id? 'selected':''}`}>
            <div className='item-left'>
              <div className='item-id'>
                {item.id}
              </div>
              <div className='item-lock'>
                <i className={`fa fa-${SessionUtils.isLocked(item)? 'lock':'unlock'} icon-in-btn`}/>
              </div>
            </div>
            <div className='item-right'>
              <EditIconButton data={item} disabled={!canEdit} onEdit={handleEdit}/>
              <DuplicateIconButton data={item} onDuplicate={handleDuplicate}/>
              <DeleteIconButton data={item} disabled={!canDelete} onDelete={handleDelete}/>
            </div>
            <div className='item-center'>
              <div className='item-center-title'>
                {item.hostUser.name}
                <div className='pull-right'>
                  {moment(item.date).format('DD MMM')} {moment(item.start).format('HH:mm')}
                </div>
              </div>
              <div className='item-center-body'>
                {/*<td className='text-center'>{item.total_students}</td>*/}
                <div className='student-name'>
                  {item.total_students === 0 && 'Students Not Allocated'}
                  {item.total_students === 1 && item.students && item.students[0].name}
                  { (item.total_students || 0) > 1 && `Group Lesson of ${item.total_students} students`}
                </div>
              </div>
              <div className='item-center-footer'>
                <ul className='item-status-bar'>
                  <li><label>Lesson</label><span>{item.product?.product_key}</span></li>
                  <li><label>PO</label><span>{SessionUtils.getPODisplayNumber(item)}</span></li>
                  <li><label>PO Status</label><span>{SessionUtils.getPOStatusDisplayValue(item)}</span></li>
                  <li><label>Vendor Invoice</label><span> {item.vendor_invoice_number || '-'}</span></li>
                  <li><label>Status</label><span> {SessionUtils.getStatusDisplayValue(item.status)}</span></li>
                </ul>
              </div>
            </div>
          </div>;
        })}
      </div>
    </div>
  </div>;
}
