import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi, EndpointBuilder} from '@reduxjs/toolkit/query/react';
import {User} from '@apps/core/src/models/entities/user';

const QueryTypes = {
  UserRegistrations: 'UserRegistrations',
};

function getUrl(relativePath: string){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/admin/registrations${relativePath}.json`;
}

function getUserRegistrationsQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{users: User[]}, void>({
    query: () => {
      return {
        url: getUrl(''),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.users, QueryTypes.UserRegistrations),
  });
}

function getUserRegistrationQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{user: User}, number>({
    query: (id) => {
      return {
        url: getUrl(id.toString()),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result?.user, QueryTypes.UserRegistrations),
  });
}

function createMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{user: User}, User>({
    query: (data) => ({
      url: getUrl(''),
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.user], QueryTypes.UserRegistrations),
  });
}

function updateMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{user: User}, User>({
    query: (data) => ({
      url: getUrl(`/${data.id}`),
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.user], QueryTypes.UserRegistrations),
  });
}

function deleteMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{user: User}, number>({
    query: (id) => ({
      url: getUrl(`/${id}/delete`),
      method: 'POST',
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.user], QueryTypes.UserRegistrations),
  });
}

function markRegisteredMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{user: User}, number>({
    query: (id) => {
      return {
        url: getUrl(`/${id}/mark-registered`),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.user], QueryTypes.UserRegistrations),
  });
}

function markCancelledMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{user: User}, number>({
    query: (id) => {
      return {
        url: getUrl(`/${id}/mark-cancelled`),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.user], QueryTypes.UserRegistrations),
  });
}

export const UserRegistrationsServiceOptions = {
  reducerPath: 'UserRegistrationsService',
  keepUnusedDataFor: 60*15,
  baseQuery: fetchAppBaseQuery('UserRegistrationsService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build) => ({
    getAll: getUserRegistrationsQuery(build),
    get: getUserRegistrationQuery(build),
    create: createMutation(build),
    update: updateMutation(build),
    delete: deleteMutation(build),
    completeRegistration: markRegisteredMutation(build),
    cancelRegistration: markCancelledMutation(build),
  }),
};

export const UserRegistrationsService = createApi(UserRegistrationsServiceOptions);
