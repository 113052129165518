import React, {useCallback} from 'react';
import {ErrorMessage, useFormikContext} from 'formik';
import {getFormikFieldError, getFormikFieldValue, isFormikFieldTouched} from './get-formik-field-value';
import debounce from 'lodash.debounce';
import {isDefined} from '@framework/core';
import {FormikControlProps} from './formik-control-props';

type FormikTextFieldProps = {
  id?: string,
  name: string,
  type?: string,
  onChange?: (evt, val) => void,
  maxLength?: number,
  allowNull?: boolean,
  InputProps?: any,
  multiline?: boolean,
  size?: string,
  readonly?: boolean
} & FormikControlProps;

export const FormikTextField = (props:FormikTextFieldProps) => {
  const formik = useFormikContext();

  const {allowNull=true, type, ...otherProps} = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTextInputChangeDebounce = useCallback(
    debounce((func) => func(), 700),
    [],
  );

  const textFieldProps:any = {
    onBlur: formik.handleBlur,
    type: type,
    value: null,
    // put props at the end so we can overwrite
    // other propertyIncomes from the component props
    ...otherProps,
  };

  textFieldProps.onChange = (evt) => {
    let value = allowNull === true && evt.target.value === '' ? null : evt.target.value;

    const newEvent = {...evt};
    newEvent.target = {
      name: evt.target.name,
      value: value,
      // this is a workaround to formik on change event
      // that on number type it does not return null values
      // so when value is null we force the type to text
      type: value === null ? 'text' : type,
    };

    formik.handleChange(newEvent);

    if (props.onChange) {
      if (type === 'number'){
        value = parseFloat(value);
      }
      onTextInputChangeDebounce(() => {
        if (props.onChange){
          props.onChange(evt, value);
        }
      });
    }
  };

  const formikValue = getFormikFieldValue(formik, props.name);
  const error = isFormikFieldTouched(formik, props.name) && Boolean(getFormikFieldError(formik, props.name));
  // const helperText = isFormikFieldTouched(formik, props.name) && getFormikFieldError(formik, props.name);
  textFieldProps.value = isDefined(formikValue) ? formikValue : '';

  if (error) {
    textFieldProps.className = `${textFieldProps.className} is-invalid`;
  }

  return (
    <>
      <input {...textFieldProps} className={`form-control ${textFieldProps.className}`}/>
      <ErrorMessage component='div' name={props.name} className='invalid-feedback'/>
    </>
  );
};
