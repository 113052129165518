import type {PayloadAction} from '@reduxjs/toolkit';
import {addListener, removeListener, createSlice} from '@reduxjs/toolkit';
import {isDefined} from '@framework/core';
import {v4 as uuidv4} from 'uuid';

export type DialogState = {
  id: string;
  type: any;
  open: boolean,
  config?: any,
  data?: any,
};

type DismissDialogActionPayload = {
  id: string;
  type: any;
};

type DialogReduxState = {
  dialogs:{[key: string]:DialogState},
  dialogTypes: any[]
};

const initialState:DialogReduxState = {
  dialogs: {},
  dialogTypes: [],
};

function checkDialogTypeRegistration(registry: any[], dlgType: any){
  const exist = registry.find((d) => d === dlgType);
  if (!exist){
    // eslint-disable-next-line no-console
    console.error(`${dlgType} dialog is not registered for use, please call dispatch(registerDialogType(<DlgClass>)); in useEffect to register dialogs, or the dialog component is not added to the app`);
  }
}

const dialogStateSlice = createSlice({
  name: 'dialogState',
  initialState: initialState,
  reducers: {
    unregisterDialogType: (state, action:PayloadAction<any>) => {
      const dlgType = action.payload;
      const exist = state.dialogTypes.find((d) => d === dlgType);

      if (exist){
        state.dialogTypes = state.dialogTypes.filter((d) => d === dlgType);
      }
    },
    registerDialogType: (state, action:PayloadAction<any>) => {
      const dlgType = action.payload;
      const exist = state.dialogTypes.find((d) => d === dlgType);

      if (exist){
        const len = state.dialogTypes.length;
        // eslint-disable-next-line no-console
        console.warn(`dialog type ${dlgType} was already registered, total ${len} registered`);
      } else {
        state.dialogTypes.push(dlgType);
      }
    },
    showDialog: (state, action:PayloadAction<DialogState>) => {
      const dialogType = action.payload.type;
      const id = action.payload.id;
      const data = action.payload.data;
      const config = action.payload.config || {};

      const dialogId = dialogType;

      checkDialogTypeRegistration(state.dialogTypes, dialogType);

      const guid = uuidv4();
      state.dialogs[dialogId] = {
        id: id || guid,
        type: dialogType,
        open: true,
        config: config,
        data,
      };
    },
    dismissDialog: (state, action:PayloadAction<DismissDialogActionPayload>) => {
      const dialogType= action.payload.type;
      const id = action.payload.id;
      const dialogs = state.dialogs;
      const dialogId = dialogType;

      const dlg = dialogs[dialogId];

      if (dlg && dlg.id === id){
        dlg.open = false;
        dialogs[dialogId] = dlg;
      }
      state.dialogs = dialogs;
    },
    updateDialog: (state, action:PayloadAction<DialogState>) => {
      const dialogType = action.payload.type;
      const id = action.payload.id;
      const dialogId = dialogType;

      const data = action.payload.data;
      const config = action.payload.config;
      const dlg = state.dialogs[dialogId];
      if (dlg && dlg.id === id){
        dlg.data = data || dlg.data;
        dlg.config = config || dlg.config;
        state.dialogs[dialogId] = {...dlg};
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fireAction: (state, payloadAction) => {
      // pass
    },
  },
});

function getDialogType(type:any){
  if (typeof(type) === 'function'){
    if (!type.DialogTypeName){
      throw new Error(`${type.name} does not have DialogTypeName property defined, it is mandatory to make sure dialog works on production build`);
    }
    return type.DialogTypeName;
  }
  return type;
}

// function getDialogTypeId(type, id){
//   let typeName = getDialogType(type);
//   if (!isBlank(id)){
//     typeName = `${typeName}/${id}`;
//   }
//   return typeName;
// }

export const selectHasDialog = (state, dialogType: string): boolean => {
  const dialogId = getDialogType(dialogType);
  return isDefined(state.dialogState.dialogs[dialogId]);
};

export const selectDialogState = (state, dialogType: string): DialogState => {
  const dialogId = getDialogType(dialogType);
  return state.dialogState.dialogs[dialogId];
};

export function showDialog(dialogType: any, instanceId: string, data?: any, config?: any){
  const type= getDialogType(dialogType);
  return dialogStateSlice.actions.showDialog({
    type, id: instanceId, open: true, data, config,
  });
}

export function dismissDialog(dialogType, instanceId: string){
  const type= getDialogType(dialogType);
  return dialogStateSlice.actions.dismissDialog({
    type, id: instanceId,
  });
}

export function updateDialog(dialogType: any, instanceId: string, data?:any, config?:any){
  const type= getDialogType(dialogType);
  return dialogStateSlice.actions.updateDialog({
    type, id: instanceId, open: true, data, config,
  });
}

export function addDialogActionListener (
  dialogType:any, instanceId:string, actionName:string,
  func:(dialogData, result, action, listenerApi) => void,
){
  const type= getDialogType(dialogType);
  return addListener({
    actionCreator: dialogStateSlice.actions.fireAction,
    effect: async (action, listenerApi) => {
      const payload = action.payload;
      const dlgType = payload.type;
      const instId = payload.id;
      const act = payload.action;
      const result = payload.data;
      const dialogData = payload.dialogData;
      if (dlgType === type && instId === instanceId && act === actionName){
        // listenerApi.cancelActiveListeners();
        func(dialogData, result, action, listenerApi);
      }
    },
  });
}

export function removeDialogActionListener(
  dialogType:any, instanceId:string, actionName:string,
){
  const type= getDialogType(dialogType);
  return removeListener({
    actionCreator: dialogStateSlice.actions.fireAction,
    effect: async (action, listenerApi) => {
      const payload = action.payload;
      const dlgType = payload.type;
      const instId = payload.id;
      const act = payload.action;
      if (dlgType === type && instId === instanceId && act === actionName){
        // listenerApi.cancelActiveListeners();
        listenerApi.unsubscribe();
      }
    },
  });
}

export function fireDialogAction(dialogType:any, instanceId:string, action:string, dialogData?:any, data?:any){
  const type = getDialogType(dialogType);
  return dialogStateSlice.actions.fireAction({
    type, id: instanceId, action, data,
    dialogData,
  });
}

export function registerDialogType(dialogType:any){
  const type = getDialogType(dialogType);
  return dialogStateSlice.actions.registerDialogType(type);
}

export function unregisterDialogType(dialogType:any){
  const type = getDialogType(dialogType);
  return dialogStateSlice.actions.unregisterDialogType(type);
}

export const dialogStateReducer = dialogStateSlice.reducer;
