import {useDispatch} from 'react-redux';
import {loginActions} from '@apps/core';

export function LogoutLink(props){
  const dispatch = useDispatch();
  const handleClick = function(){
    dispatch(loginActions.logoutSuccess());
  };
  return <a {...props} onClick={handleClick}>{props.children}</a>;
}
