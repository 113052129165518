import {loginActions, UserService} from '@apps/core';
import {useDispatch} from 'react-redux';
import './change-user-token.scss';
import {useTranslation} from 'react-i18next';

export function ChangeUserToken(props){
  const {user} = props;
  const {t} = useTranslation();

  const [logoutTrigger] = UserService.useLogoutMutation();
  const dispatch = useDispatch();

  const handleChangeStudentClick = function (){
    logoutTrigger().then(() => dispatch(loginActions.logoutSuccess()));
  };

  return <div className='change-user-token'>
    <div className='row'>
      <div className='col-md-8 col-sm-6 client-name'>
        {user.first_name} {user.last_name}
      </div>
      <div className='col-md-4 col-sm-6 buttons'>
        <button className='btn btn-primary' onClick={handleChangeStudentClick}>{t('Logout')}</button>
      </div>
    </div>
  </div>;
}
