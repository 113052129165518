import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi, EndpointBuilder} from '@reduxjs/toolkit/query/react';
import {LoginResponse} from '../models/app/login-response';

const QueryTypes:{[key:string]: string}= {
  CurrentUser: 'CurrentUser',
};

function getUrl(relativePath:string){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/user/${relativePath}.json`;
}

function loginMutation(build:EndpointBuilder<any, string, string>){
  return build.mutation<LoginResponse, {email: string, password: string}>({
    query: (data) => {
      return {
        url: getUrl('login'),
        method: 'POST',
        body: data,
      };
    },
    // transformResponse: (response) => response.data,
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result, QueryTypes.CurrentUser),
  });
}

function verifyTokenMutation(build:EndpointBuilder<any, string, string>){
  return build.mutation<string, {token: string}>({
    query: (data) => ({
      url: getUrl('token'),
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result, QueryTypes.CurrentUser),
  });
}

function resetTokenMutation(build:EndpointBuilder<any, string, string>){
  return build.mutation<string, {token: string}>({
    query: (data) => ({
      url: getUrl('token/reset'),
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result, QueryTypes.CurrentUser),
  });
}

function logoutMutation(build:EndpointBuilder<any, string, string>){
  return build.mutation<string, void>({
    query: () => ({
      url: getUrl('logout'),
      method: 'POST',
    }),
    transformResponse: (response) => response.data,
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result, QueryTypes.CurrentUser),
  });
}

function checkSessionQuery(build:EndpointBuilder<any, string, string>){
  return build.query<{active: boolean}, void>({
    query: () => ({
      url: getUrl('check-session'),
      method: 'GET',
    }),
  });
}

export const UserServiceOptions = {
  reducerPath: 'UserService',
  keepUnusedDataFor: 0,
  baseQuery: fetchAppBaseQuery('UserService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build:EndpointBuilder<any, string, any>) => ({
    login: loginMutation(build),
    logout: logoutMutation(build),
    token: verifyTokenMutation(build),
    resetToken: resetTokenMutation(build),
    checkSession: checkSessionQuery(build),
  }),
};

export const UserService = createApi(UserServiceOptions);
