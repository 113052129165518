import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi, EndpointBuilder} from '@reduxjs/toolkit/query/react';
import {Product} from '@apps/core/src/models/entities/product';

const QueryTypes = {
  Products: 'Products',
};

function getUrl(relativePath){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/admin/products${relativePath}.json`;
}

function getProductsQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{products:Product[]}, any>({
    query: (urlParams) => {
      return {
        url: getUrl(''),
        params: urlParams,
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.products, QueryTypes.Products),
  });
}

function refreshProductsMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{products: Product[]}, void>({
    query: () => {
      return {
        url: getUrl('/refresh'),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([], QueryTypes.Products),
  });
}


export const ProductsServiceOptions = {
  reducerPath: 'ProductsService',
  keepUnusedDataFor: 60*15,
  baseQuery: fetchAppBaseQuery('ProductsService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build) => ({
    getProducts: getProductsQuery(build),
    refreshProducts: refreshProductsMutation(build),
  }),
};

export const ProductsService = createApi(ProductsServiceOptions);
