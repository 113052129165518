import {globalConfigRegistry} from '@framework/core';
import {fetchAppBaseQuery, RtkQueryUtils} from '@framework/rtk-query';
import {createApi, EndpointBuilder} from '@reduxjs/toolkit/query/react';
import {ClassAssignment} from '@apps/core/src/models/entities/class-assignment';
import {ClassSession} from '@apps/core/src/models/entities/class-session';
import {PaginationParams} from '@apps/core/src/models/app/pagination-params';
import {ClassSessionChangeHistory} from '@apps/core/src/models/entities/class-session-change-history';
import {PurchaseOrder} from '@apps/core';
import {CalendarEvent} from '@apps/core/src/models/synology/calendar-event';

const QueryTypes = {
  ClassSessions: 'ClassSessions',
  Histories: 'ClassSessions/Histories',
  ClassAssignments: 'ClassSessions/Assignments',
};

function listAssignmentTags(assignments: ClassAssignment[]){
  const tagType = QueryTypes.ClassAssignments;
  const tagId = 'LIST';
  const tags = assignments
    ? [
      {type: tagType, id: tagId},
      ...assignments.map(({class_session_id, id}) => ({type: tagType, id: `${class_session_id}-${id}`})),
      ...assignments.map(({class_session_id, id}) => ({type: QueryTypes.ClassSessions, id: class_session_id})),
    ]
    : [{type: tagType, id: tagId}];
  return tags;
}

function getUrl(relativePath: string | number){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/admin/sessions/${relativePath}.json`;
}

function getClassSessionsQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{sessions:ClassSession[]}, void>({
    query: () => {
      return {
        url: getUrl(''),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.sessions, QueryTypes.ClassSessions),
  });
}

function getClassSessionQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{session: ClassSession}, number>({
    query: (id) => {
      return {
        url: getUrl(id.toString()),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.itemTags(result?.session, QueryTypes.ClassSessions),
  });
}

function createClassSessionMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{session: ClassSession}, ClassSession>({
    query: (data) => ({
      url: getUrl(''),
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.session], QueryTypes.ClassSessions),
  });
}

function cloneClassSessionMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{session: ClassSession}, ClassSession>({
    query: (data) => ({
      url: getUrl(`${data.id}/clone`),
      method: 'POST',
      body: data,
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.session, QueryTypes.ClassSessions),
  });
}

function updateClassSessionMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{session: ClassSession}, ClassSession>({
    query: (data) => ({
      url: getUrl(data.id || 0),
      method: 'PUT',
      body: data,
    }),
    invalidatesTags: (result) => {
      return RtkQueryUtils.tagProviders.listTags([result?.session], QueryTypes.ClassSessions);
    },
  });
}

function takeActionMutation(build:EndpointBuilder<any, string, any>, action){
  return build.mutation<{session: ClassSession}, number>({
    query: (id) => ({
      url: getUrl(`${id}/${action}`),
      method: 'POST',
    }),
    invalidatesTags: (result, error) => {
      if (!error){
        return RtkQueryUtils.tagProviders.listTags([result?.session], QueryTypes.ClassSessions);
      }
      return [];
    },
  });
}

function deleteClassSessionMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{success: boolean, session: ClassSession}, number>({
    query: (id) => ({
      url: getUrl(`${id}/delete`),
      method: 'POST',
    }),
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.session], QueryTypes.ClassSessions),
  });
}

function getTimelineBasedSessionsQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{sessions: ClassSession[]}, {timeline: string} & PaginationParams>({
    query: ({timeline, page = 1, limit = 15}) => {
      return {
        url: getUrl(`timeline/${timeline}`),
        params: {page, limit},
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.sessions, QueryTypes.ClassSessions),
  });
}

function getChangeHistoriesQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{histories: ClassSessionChangeHistory[]}, number>({
    query: (id) => {
      return {
        url: getUrl(`${id}/histories`),
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.histories, QueryTypes.Histories),
  });
}

// function refreshClassSessionsMutation(build:EndpointBuilder<any, string, any>){
//   return build.mutation({
//     query: () => {
//       return {
//         url: getUrl('refresh'),
//         method: 'POST',
//       };
//     },
//     invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result.item], QueryTypes.ClassSessions),
//   });
// }

function addToPurchaseOrderMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{item: PurchaseOrder}, {id: number, po_id: string}>({
    query: ({id, po_id}) => {
      return {
        url: getUrl(`${id}/add-to-po`),
        method: 'POST',
        body: {po_id},
      };
    },
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.item], QueryTypes.ClassSessions),
  });
}

function removeFromPurchaseOrderMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{item: PurchaseOrder}, {id: number, po_id: string}>({
    query: ({id, po_id}) => {
      return {
        url: getUrl(`${id}/remove-from-po`),
        method: 'POST',
        body: {po_id},
      };
    },
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.item], QueryTypes.ClassSessions),
  });
}

function syncPurchaseOrderNumberMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{result: PurchaseOrder}, number>({
    query: (id) => {
      return {
        url: getUrl(`${id}/sync-po-number`),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => RtkQueryUtils.tagProviders.listTags([result?.result], QueryTypes.ClassSessions),
  });
}

function syncCalendarMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{event:CalendarEvent}, number>({
    query: (id) => {
      return {
        url: getUrl(`${id}/sync-calendar`),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => [],
  });
}

/** class assignments **/
function getAssignmentUrl(session_id: number, relativePath: string|number){
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/admin/sessions/${session_id}/assignments${relativePath}.json`;
}

function getClassAssignmentsQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{sessions: ClassAssignment[]}, {session_id: number}>({
    query: ({session_id}) => {
      return {
        url: getAssignmentUrl(session_id, ''),
      };
    },
    providesTags: (result) => listAssignmentTags(result?.sessions || []),
  });
}

function getClassSessionAssignmentQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{session: ClassSession}, {session_id: number, id: number}>({
    query: ({session_id, id}) => {
      return {
        url: getAssignmentUrl(session_id, id),
      };
    },
    providesTags: (result) => listAssignmentTags([result?.session || {}]),
  });
}

function addAssignmentMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{session: ClassSession}, {class_session_id: number, user_id: number}>({
    query: (data) => {
      return {
        url: getAssignmentUrl(data.class_session_id, ''),
        method: 'POST',
        body: data,
      };
    },
    invalidatesTags: (result) => listAssignmentTags([result?.session || {}]),
  });
}

function removeAssignmentMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{session: ClassSession}, {session_id: number, id: number}>({
    query: ({session_id, id}) => {
      return {
        url: getAssignmentUrl(session_id, `/${id}/delete`),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => listAssignmentTags([result?.session || {}]),
  });
}

function markAttendedMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{session: ClassSession}, {session_id: number, id: number}>({
    query: ({session_id, id}) => {
      return {
        url: getAssignmentUrl(session_id, `/${id}/mark-attended`),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => listAssignmentTags([result?.session || {}]),
  });
}

function markAbsenceMutation(build:EndpointBuilder<any, string, any>){
  return build.mutation<{session: ClassSession}, {session_id: number, id: number}>({
    query: ({session_id, id}) => {
      return {
        url: getAssignmentUrl(session_id, `/${id}/mark-attended`),
        method: 'POST',
      };
    },
    invalidatesTags: (result) => listAssignmentTags([result?.session || {}]),
  });
}


/** get users assignments **/
function getUserSessionUrl(relativePath: string | number) {
  const appConfig = globalConfigRegistry.appConfig;
  return `${appConfig.api}/admin/users/${relativePath}.json`;
}

function getClassAssignmentsByUserQuery(build:EndpointBuilder<any, string, any>){
  return build.query<{sessions: ClassAssignment[]}, {id: number} & PaginationParams>({
    query: ({id, page=1, limit=25}) => {
      return {
        url: getUserSessionUrl(`${id}/sessions`),
        params: {page, limit},
      };
    },
    providesTags: (result) => RtkQueryUtils.tagProviders.listTags(result?.sessions, QueryTypes.ClassSessions),
  });
}

export const ClassSessionsServiceOptions = {
  reducerPath: 'ClassSessionsService',
  keepUnusedDataFor: 60*15,
  baseQuery: fetchAppBaseQuery('ClassSessionsService'),
  tagTypes: Object.keys(QueryTypes).map((key) => QueryTypes[key]),
  endpoints: (build) => ({
    getClassSessions: getClassSessionsQuery(build),
    getClassSession: getClassSessionQuery(build),
    createClassSession: createClassSessionMutation(build),
    cloneClassSession: cloneClassSessionMutation(build),
    updateClassSession: updateClassSessionMutation(build),
    deleteClassSession: deleteClassSessionMutation(build),
    lock: takeActionMutation(build, 'lock'),
    unlock: takeActionMutation(build, 'unlock'),
    markCompleted: takeActionMutation(build, 'complete'),
    markConfirmed: takeActionMutation(build, 'confirm'),
    markCanceled: takeActionMutation(build, 'cancel'),
    markDraft: takeActionMutation(build, 'draft'),
    getTimelineBasedSessions: getTimelineBasedSessionsQuery(build),
    getChangeHistories: getChangeHistoriesQuery(build),
    // refreshClassSessions: refreshClassSessionsMutation(build),
    addToPurchaseOrder: addToPurchaseOrderMutation(build),
    removeFromPurchaseOrder: removeFromPurchaseOrderMutation(build),
    syncPurchaseOrderNumber: syncPurchaseOrderNumberMutation(build),
    syncCalendar: syncCalendarMutation(build),

    /** assignments **/
    getAssignments: getClassAssignmentsQuery(build),
    getAssignment: getClassSessionAssignmentQuery(build),
    addAssignment: addAssignmentMutation(build),
    removeAssignment: removeAssignmentMutation(build),
    markAttended: markAttendedMutation(build),
    markAbsence: markAbsenceMutation(build),
    getAssignmentsByUser: getClassAssignmentsByUserQuery(build),
  }),
};

export const ClassSessionsService = createApi(ClassSessionsServiceOptions);
